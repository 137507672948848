var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._b({ staticClass: "user" }, "div", _vm.integration, false),
    [
      _c("div", { staticClass: "logo" }, [
        _vm.logo !== "" && _vm.logoUrl == ""
          ? _c("img", { attrs: { src: _vm.logo } })
          : _vm.logo !== "" && _vm.logoUrl !== ""
          ? _c("a", { attrs: { href: _vm.logoUrl } }, [
              _c("img", { attrs: { src: _vm.logo } }),
            ])
          : _vm._e(),
        _vm.mixinGetIntegrationInfo().mode == "booking"
          ? _c(
              "div",
              [
                _c("p", { staticClass: "resumeText" }, [
                  _vm._v(
                    "\n                Stai utilizzando il Salabam " +
                      _vm._s(
                        _vm._f("capitalize")(
                          _vm.mixinGetIntegrationInfo().integration.salabam
                            .focus
                        )
                      ) +
                      "\n            "
                  ),
                ]),
                _c("BoxsetResume", {
                  attrs: {
                    focus: this.integration.salabam.focus,
                    band: this.integration.salabam.band,
                    nights: this.integration.salabam.nights,
                    price: parseInt(this.integration.salabam.public_price),
                    bookingHidePrice: this.$config.guiSettings.bookingHidePrice,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm.integrated
        ? _c("div", { staticClass: "info" }, [
            _c("div", { staticClass: "content" }, [
              _vm.integrated.salabam && _vm.integrated.salabam.isGiftCard
                ? _c("div", { staticClass: "mr-4" }, [
                    _c("span", { staticClass: "displayNameWelcome" }, [
                      _vm._v("Benvenuto"),
                    ]),
                    _c("br"),
                    _vm.displayName != ""
                      ? _c("span", {
                          staticClass: "displayName",
                          domProps: { innerHTML: _vm._s(_vm.displayName) },
                        })
                      : _vm._e(),
                  ])
                : _c("div", { staticClass: "mr-4" }, [
                    _c("p", { staticClass: "mb-0" }, [
                      _c("span", { staticClass: "displayNameWelcome" }, [
                        _vm._v("Benvenuto "),
                      ]),
                      _vm.displayName != ""
                        ? _c("span", {
                            staticClass: "displayName",
                            domProps: { innerHTML: _vm._s(_vm.displayName) },
                          })
                        : _vm._e(),
                    ]),
                    _c("span", { staticClass: "availability" }, [
                      _vm.ssoWelfare && _vm.availability >= 0
                        ? _c("span", [
                            _c("span", { staticClass: "creditCopy" }, [
                              _vm._v("\n                            Credito"),
                              _vm.mixinGetIntegrationInfo().integration.welfare
                                ? _c("span", [_vm._v(" welfare")])
                                : _vm._e(),
                              _vm._v(": "),
                            ]),
                            _vm._v(
                              _vm._s(_vm.availability) +
                                " €\n                        "
                            ),
                          ])
                        : _vm._e(),
                      _vm.ssoSalabam && _vm.email != ""
                        ? _c("small", {}, [_vm._v(_vm._s(_vm.email)), _c("br")])
                        : _vm._e(),
                      _vm.ssoSalabam && _vm.confirmcode != ""
                        ? _c("small", {}, [_vm._v(_vm._s(_vm.confirmcode))])
                        : _vm._e(),
                    ]),
                  ]),
              _c(
                "span",
                {
                  staticClass: "logout",
                  on: {
                    click: function ($event) {
                      return _vm.mixinLogout()
                    },
                  },
                },
                [_c("SvgLogout")],
                1
              ),
            ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }