<template>
    <div class="navigation" @click="closeMenu()">
        <ul class="links">
            <li><a href="http://www.salabamsolutions.com" target="_blank">Salabam Solutions</a></li>
            <li v-if="mixinGetIntegrationInfo().mode == 'boxset'"><router-link :to="{name: 'shop'}">Shop</router-link></li>
            <li><router-link :to="{name: 'blog'}">Blog</router-link></li>            
            <li class="menuItemWithIcon">
                <a href="#" @click="mixinGoTo('gift-cards',{})">
                    <SvgGift />Regala
                </a>
            </li>
            <!--<li><a href="/assistenza#contatti-contattaci">Assistenza</a></li>-->


            <!--<li v-if="this.mixinGetIntegrationInfo().integration == false"><router-link :to="{name: 'homepage'}">Ho un buono sconto</router-link></li>-->
            <!--usare la versione commentata per nascondere "ho un buyono sconto" agli utenti già loggati con un salabam-->
            <!--<li><router-link :to="{name: 'homepage'}">Ho un buono sconto</router-link></li>-->

            <!--<li><router-link :to="{name: 'assistenza-covid19'}">Covid-19</router-link></li>-->

            <li v-if="this.mixinGetIntegrationInfo().mode != 'booking'">
                <router-link :to="{name: 'login-salabam'}">
                    <b-button size="sm" variant="primary" class="white">{{'ho un salabam' | uppercase}}</b-button>
                </router-link>
            </li>

            <!-- torna al sito classico, mando gli integrati allo shop, gli altri al login -->
            <!-- se è specificato PUBLICPATH il sito è stato rilasciato in produzione sullo stesso host
                 quindi inibisco la possibilità di tornare al sito classico, che non c'è più -->
            <li v-if="PUBLICPATH != 'cancellara' && this.mixinGetIntegrationInfo().sso && !this.mixinGetIntegrationInfo().integration == 'jointly'">
              <a :href="legacyUrl + 'link/sito-classico'" @click="trackBackToLegacy()">
                <b-button size="sm" variant="secondary" class="white">{{'sito classico' | uppercase}}</b-button>
              </a>
            </li>
            <li v-else-if="PUBLICPATH != 'cancellara' && (this.$route.name == 'login-salabam' || this.$route.name == 'login-salabam-fields') && !this.mixinGetIntegrationInfo().integration == 'jointly'">
              <a :href="legacyUrl + 'link/login-classico'" @click="trackBackToLegacy()">
                <b-button size="sm" variant="secondary" class="white">{{'sito classico' | uppercase}}</b-button>
              </a>
            </li>

        </ul>
    </div>    
</template>

<script>
import Vue from 'vue'
import SvgGift from '../../svg/gift'
export default {
    name: 'navigation',
    components: {
        SvgGift,
    },
    data() {
      return {
        legacyUrl: Vue.prototype.$config.site.legacyUrl,
        PUBLICPATH: process.env.VUE_APP_PUBLICPATH,
      }
    },
    methods: {
      trackBackToLegacy() {

        if(process.env.VUE_APP_MODE != 'production') window.console.log('trackBackToLegacy()')

        if(this.$gtm) this.$gtm.trackEvent({
          event: 'customEvent',
          category: 'userBehaviour',
          action: 'backToLegacy',
          label: (Vue.prototype.$config.integration && Vue.prototype.$config.integration.email)? Vue.prototype.$config.integration.email : '',
        })

      },
      closeMenu(){
        // chiude il menu mobile quando viene cliccato un link
        // altrimenti resta aperto e non ci si accorge che sotto è cambiata la pagina
        return this.$parent.closeMenu()
      }
    }
}
</script>

<style lang="scss">
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/mixins";
    @import './../../../scss/_custom.scss';
    .navigation
    {
        .links
        {
            display:flex;
            justify-content: flex-end;
            align-items:center;
            flex-direction:column;
            li
            {
                font-size: 13px;
                margin-bottom: 1.25rem;

                @include media-breakpoint-up(lg) {
                    margin-bottom: 0;
                }

                a
                {
                    color:$black;
                    &:hover
                    {
                        text-decoration:underline;
                    }
                }
                button
                {
                    margin-top:0.25rem;
                    font-size: 11px !important;
                }
            }
            @include media-breakpoint-up(lg)
            {
                margin:0;
                padding:0;
                flex-direction:row;
                li
                {
                    padding:0 1.4rem;
                    position:relative;
                    &:before
                    {
                        content:"";
                        position:absolute;
                        top:50%;
                        transform:translateY(-50%);
                        left:0;
                        width:1px;
                        height:24px;
                        background:$gray-300;
                    }
                    &:first-child
                    {
                        &:before
                        {
                            display:none;
                        }
                    }
                    &:last-child
                    {
                        /*
                        margin-right:1.5rem;
                        &:after
                        {
                            content:"";
                            position:absolute;
                            top:50%;
                            transform:translateY(-50%);
                            right:0;
                            width:1px;
                            height:24px;
                            background:$gray-300;
                        }
                        */
                    }
                }
            }
        }
        @include media-breakpoint-only(lg)
        {
            .links
            {
                li
                {
                    padding-left:0.75rem;
                    padding-right:0.75rem;
                }
            }
        }

        .menuItemWithIcon
        {
            svg,img
            {
                transform:translateY(-3px);
                max-width:14px;
                height:auto;
                text-decoration:none !important;
                margin-right:6px;
                *
                {
                    fill:$danger;
                }
            }
        }
    }
</style>