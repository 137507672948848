<template>
    <div class="user" v-bind="integration">
        <div class="logo">
          
            <img :src="logo" v-if="logo !== '' && logoUrl == ''" />
            <a :href="logoUrl" v-else-if="logo !== '' && logoUrl !== ''"><img :src="logo"></a>
          
            <div v-if="mixinGetIntegrationInfo().mode == 'booking'">
                <p class="resumeText">
                    Stai utilizzando il Salabam {{mixinGetIntegrationInfo().integration.salabam.focus | capitalize}}
                </p>
                <BoxsetResume
                    :focus="this.integration.salabam.focus"
                    :band="this.integration.salabam.band"
                    :nights="this.integration.salabam.nights"
                    :price="parseInt(this.integration.salabam.public_price)"
                    :bookingHidePrice="(this.$config.guiSettings.bookingHidePrice)"
                />
            </div>
        </div>
        <div class="info" v-if="integrated">
            <!--<div class="avatar" :style="'background-image:url('+avatar+')'"></div>-->
            <div class="content">
                <div class="mr-4" v-if="integrated.salabam && integrated.salabam.isGiftCard">
                    <span class="displayNameWelcome">Benvenuto</span>
                    <br/>
                    <span v-if="displayName != ''" class="displayName" v-html="displayName"></span>
                    <!-- TODO inserire data scadenza -->
                </div>
                <div class="mr-4" v-else>
                    <p class="mb-0">
                      <span class="displayNameWelcome">Benvenuto </span>
                      <span v-if="displayName != ''" class="displayName" v-html="displayName"></span>
                      <!--<span v-if="ssoWelfare" class="displayName">{{email}}</span>
                      <span v-if="ssoWelfare" class="displayName">{{confirmcode}}</span>-->
                    </p>
                    <span class="availability">
                        <span v-if="ssoWelfare && availability >= 0">
                            <span class="creditCopy">
                                Credito<span v-if="mixinGetIntegrationInfo().integration.welfare"> welfare</span>: </span>{{availability}} €
                            </span>
                        <small class="" v-if="ssoSalabam && email != ''">{{email}}<br/></small> <!-- TODO rimuovere -->
                        <small class="" v-if="ssoSalabam && confirmcode != ''">{{confirmcode}}</small>
                        <!-- TODO inserire data scadenza -->
                    </span>
                </div>
                <span class="logout" @click="mixinLogout()">
                    <SvgLogout />
                </span>
            </div>
        </div>
    </div>    
</template>

<script>
import SvgLogout from '../../svg/logout'
import BoxsetResume from '../boxset/boxset-resume'

export default {
    name: 'user',
    components:
    {
        SvgLogout,
        BoxsetResume,
    },
    data()
    {
        return {
            //integration: this.$config.integration,
            //integrated: (this.$config.integration !== undefined),
            ssoSalabam: false,
            ssoWelfare: false,
            displayName: '',
            email: '',
            confirmcode: '',
            //avatar: '',
            boxset: '',
            logo: '',
            logoUrl: '',
        }
    },
    computed: {
        availability: {
            get() {
                if(this.mixinGetIntegrationInfo().mode == 'boxset') return this.$store.state.integration.availability
                return 0
            },
            set() {}
        },
        integration:
        {
            get() {
                return this.$config.integration
            },
            set() {}
        },
        guiSettings:
          {
            get() {
              return this.$config.guiSettings
            },
            set() {}
          },
        integrated:
        {
            get() {
                //return this.$config.integration !== undefined
                return this.mixinGetIntegrationInfo().integration
            },
            set() {}
        }
    },
    mounted()
    {
        //window.console.log(this.$config.integration)
        //window.console.log(this.mixinGetIntegrationInfo())

        if(!this.integrated) return

        // login Salabam
        if(this.mixinGetIntegrationInfo(0).mode == 'booking')
        {
            this.ssoSalabam = true
            this.boxset = this.integration.salabam.title
            this.displayName = this.integration.displayName
            this.email = this.integration.salabam.email
            this.confirmcode = this.integration.salabam.confirmcode

            //this.avatar = this.integration.avatarSrc ? this.integration.avatarSrc : 'https://via.placeholder.com/40x40' // OLD
            //this.logo = this.$config.boxsets_images_basepath + this.integration.salabam.image // OLD

            // TODO se troviamo il posto sarebbe carino reimplementare questo anche in prenotazione
            //this.logo = this.integration.customerLogoSrc ? this.integration.customerLogoSrc : ''

            return
        }

        // login Welfare
        if(this.mixinGetIntegrationInfo(0).mode == 'boxset')
        {
            this.ssoWelfare = true
            this.displayName = this.integration.displayName
            //this.avatar = this.integration.avatarSrc ? this.integration.avatarSrc : 'https://via.placeholder.com/40x40'
            
            this.logo = this.guiSettings.customerLogoSrc ? this.guiSettings.customerLogoSrc : ''
            if(this.logo == '') this.logo = this.integration.customerLogoSrc ? this.integration.customerLogoSrc : ''

            this.logoUrl = this.guiSettings.customerLogoUrl ? this.guiSettings.customerLogoUrl : ''
            if(this.logoUrl == '') this.logoUrl = this.integration.customerLogoUrl ? this.integration.customerLogoUrl : ''
            
          
            //this.availability = this.integration.availability
            return
        }
    }
}
</script>

<style lang="scss">
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/mixins";
    @import './../../../scss/_custom.scss';
    .user
    {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align:center;
        font-size: 13px;
        margin-top:1rem;
        padding-top:1rem;
        border-top:1px solid $light-gray;
        .logo
        {
            //width:100%;
            //height:auto;
            //max-width: 180px;
            margin:0 auto;
            margin-top:0.5rem;
            img
            {
                max-height:60px;
                width:auto;
                max-width:250px;
            }

            .resumeText
            {
                text-align:left;
                margin-bottom:6px;
                font-size: 12px;
            }
            .boxsetResume
            {
                strong
                {
                    font-size: 14px;
                }
                .boxsetName {display:none;}
                .boxsetIcon {width:18px;height:18px;transform:none;}
                .salaBadge
                {
                    padding:0.15rem 0.75rem;
                    font-size: 13px;
                }
            }
        }
        .info
        {
            display:flex;
            justify-content: center;
            align-items: center;
            margin-top:1rem;
        }
        .avatar
        {
            width:40px;
            height:40px;
            border-radius:50%;
            overflow: hidden;
            background:$secondary;
            background-size:cover;
            background: center center;
        }
        .content
        {
            display:flex;
            //flex-direction: column;
            text-align:left;
            //padding-left:0.75rem;
            justify-content: center;
            align-items: center;
        }
        .logout
        {
            text-align:right;
            font-size: 10px;
            color:$danger;
            //margin-top:8px;
            cursor:pointer;
            span
            {
                margin-left:5px;
            }
            &:hover
            {
                text-decoration:underline;
            }
        }
        .displayName
        {
            font-weight:bold;
        }
        @include media-breakpoint-up(lg)
        {
            flex-direction:row;
            margin:0;
            padding:0;
            border-top:0;
            .logo
            {
                margin:0 1.5rem 0 0;
                padding:0 1.5rem 0 0;
                position:relative;
                &:before
                {
                    content:"";
                    position:absolute;
                    top:50%;
                    transform:translateY(-50%);
                    right:0;
                    width:1px;
                    height:24px;
                    background:$gray-300;
                }                 
            }
            .info
            {
                margin:0;
                padding:0;
            }            
        }

        @include media-breakpoint-only(lg)
        {
            .logo img
            {
                height:30px;
            }
            .logo
            {
                margin: 0 0.5rem 0 0;
                padding: 0 0.5rem 0 0;                
            }
            .displayNameWelcome,
            .creditCopy
            {
                display:none;
            }
            span
            {
                white-space:nowrap;
            }
        }
    }
</style>